import React from "react";
import { Parallax } from "react-scroll-parallax";
import { ReactComponent as SVG } from "../../../Images/plants/1cos.svg";
import { ReactComponent as SVG2 } from "../../../Images/plants/3cos.svg";
import UFO1 from "../../../Images/plants/ufo1.png";
import Image from "../../../component/Image/Image";
// import UFO2 from "../../../Images/plants/ufo2.png";

const FlaotingImagesOne = () => {
  return (
    <>
      <div className="plants-img-section one">
        <Parallax translateY={[-40, 40]} translateX={[0, 20]}>
          <div className="plants-img-container">
            <SVG className="mySVG" />
          </div>
        </Parallax>
      </div>
      <div className="plants-img-section two">
        <Parallax translateY={[80, -80]} translateX={[0, 10]}>
          <div className="plants-img-container">
            <SVG2 className="mySVG2" />
          </div>
        </Parallax>
      </div>
      <div className="plants-img-section ufo ufo1">
        <Parallax translateY={[80, -80]} translateX={[0, 100]}>
          <div className="plants-img-container">
            <Image src={UFO1} alt="" />
          </div>
        </Parallax>
      </div>
      {/* <div className="plants-img-section ufo ufo2">
        <Parallax translateY={[-80, 80]} translateX={[-100, 100]}>
          <div className="plants-img-container">
            <img src={UFO1} alt="" />
          </div>
        </Parallax>
      </div> */}
      {/* <div className="plants-img-section ufo ufo3">
        <Parallax translateY={[80, -80]} translateX={[-100, 100]}>
          <div className="plants-img-container">
            <img src={UFO2} alt="" />
          </div>
        </Parallax>
      </div> */}
      <div className="plants-img-section four">
        <div className="plants-img-container">
          <SVG className="mySVG" />
        </div>
      </div>
    </>
  );
};

export default FlaotingImagesOne;
