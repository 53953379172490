import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Courses from "../Pages/Courses/Courses";
import CoursesIntro from "../Pages/Courses/Course_intro";
import About from "../Pages/About/About";
import Regesteration from "../Pages/Regesteration/Regesteration";
import Register from "../Pages/Register/Register";

const AppRoutes = ({ activeBar, toggleActiveBar }) => {
  return (
    <Routes>
      <Route
        path="/home"
        element={<Home activeBar={activeBar} setActiveBar={toggleActiveBar} />}
      />
      <Route
        path="/"
        element={<Home activeBar={activeBar} setActiveBar={toggleActiveBar} />}
      />
      <Route
        path="/register"
        element={
          <Register activeBar={activeBar} setActiveBar={toggleActiveBar} />
        }
      />
      <Route
        path="/courses"
        element={
          <CoursesIntro activeBar={activeBar} setActiveBar={toggleActiveBar} />
        }
      />
      <Route
        path="/courses/choose"
        element={
          <Courses activeBar={activeBar} setActiveBar={toggleActiveBar} />
        }
      />
      <Route
        path="/courses/choose/join"
        element={
          <Regesteration activeBar={activeBar} setActiveBar={toggleActiveBar} />
        }
      />
      <Route
        path="/about"
        element={<About activeBar={activeBar} setActiveBar={toggleActiveBar} />}
      />
    </Routes>
  );
};

export default AppRoutes;
