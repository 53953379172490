import React, { useEffect } from "react";
import "./style/style.css";
import Footer from "../../component/Footer/Footer";
import Slider from "../../component/Slider/Slider";
import Video from "../../Images/Ai Motion29.mp4";
import ReactPlayer from "react-player";
import Data2 from "../../Data/Data2.json";
import { NavLink } from "react-router-dom";
import Image from "../../component/Image/Image";

const Courses_intro = ({ setActiveBar, activeBar }) => {
  useEffect(() => {
    document.title = "ZEON | Introduction";
    window.scrollTo(0, 0);
  });

  return (
    <>
      <section className="section courses-section intro">
        <Slider setActiveBar={setActiveBar} activeBar={activeBar} />

        <div className="courses img-one">
          <div className="mySpaceImage"></div> 
        </div>
        <div className="courses-wrapper">
          <div className="container ">
            {/* Heading intro */}
            <div
              // data-aos="fade-down"
              // data-aos-duration="1000"
              // data-aos-offset="100"
              className="title title-in-intro-section"
              style={{
                width: "100%",
                display: "grid",
                justifyContent: "center",
                alignItems: "center", 
              }}
            >
              {/* ----- Section ----- */}
              <h1>مقدمة</h1>
              <p>مقطع مختار من الكورس</p>
              <div className="content">
                <ReactPlayer
                  controls={true}
                  loop
                  className="react-player"
                  url={"https://www.youtube.com/watch?v=39-89nYR0LE"}
                />
              </div>
            </div>
          </div>
          {/* ----- Section ----- */}
          <div className="fang-conpanies">
            <ul>
              {Data2.fang.map((item, id) => {
                return (
                  <li key={id}>
                    <Image src={item.img} alt="lazy" />
                  </li>
                );
              })}
            </ul>
          </div>
          {/* ----- Section ----- */}
          <div className="container container-intro">
            <div className="program-feature">
              <h1>شو راح تحصل</h1>
              <div className="box-contrainer">
                {Data2["program-feature"].map((item, index) => {
                  return (
                    <div key={index} className="box">
                      <div className="img-container">
                        <Image
                          src={require(`../../Images/program_feature/${item.img}`)}
                          alt="Lazy"
                        />
                      </div>
                      <p className="title">{item.name}</p>
                      <p className="desc">{item.desc} </p>
                    </div>
                  );
                })}
              </div>
              <div className="getting-btn-container">
                <NavLink className={"join-link"} to="/courses/choose">
                  <span>انضم الان</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <Footer WaveVideo={Video} />
      </section>
    </>
  );
};

export default Courses_intro;
