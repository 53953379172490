import React, { useEffect } from "react";
import "./style/style.css";
import Data from "../../Data/Data.json";
import { Parallax } from "react-scroll-parallax";
import "aos/dist/aos.css";
import Aos from "aos";
import Image from "../Image/Image";


const Mars = () => {
  const { planets } = Data;
  useEffect(() => {
    Aos.init({
      delay: 200,
    });
  }, []);

  return (
    <section className="plants-section">
      <Parallax translateY={[-20, 10]}>
        <div
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-offset="100"
          className="container"
        >
          {planets.map((planet, index) => {
            return (
              <div key={index} className="card">
                <div className="lconSide">
                  <div className="icon-container">
                    <Image
                      src={require(`../../Images/${planet.img}`)}
                      alt="mars"
                    />
                  </div>
                  <div className="title">{planet.title}</div>
                </div>
                <div className="descSide">
                  <p>{planet.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Parallax>
    </section>
  );
};

export default Mars;
