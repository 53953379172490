import React from 'react'
import { NavLink } from 'react-router-dom';
import "./style/style.css";
import Image from '../../../Image/Image';

const Logo = () => {
  return (
    <div className="logo-container">
      <NavLink to={"home"}>
        <Image src={require("../../../../Images/zeon 3.png")} alt="" />
      </NavLink>
    </div>
  );
}

export default Logo