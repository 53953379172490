import React from "react";
// import "aos/dist/aos.css";
// import Aos from "aos";
import "./style/style.css";

const MenuList = (props) => {
  // For AOS Srolling Effect
  // useEffect(() => {
  //   Aos.init({
  //     delay: 200,
  //   });
  // }, []);
  return (
    <div className="menu-list-part">
      <ul >
        <li
          onClick={() => {
            props.setCourseName("first-stage");
          }}
        >
          المستوى الأول
        </li>
        <li
          onClick={() => {
            props.setCourseName("english");
          }}
        >
          المستوى الثاني
        </li>
      </ul>
    </div>
  );
};

export default MenuList;
