import React, { useEffect, useRef, useState } from "react";
import Slider from "../../component/Slider/Slider";
import "./style/Regesteration.css";
import Man from "../../Images/astr.png";
import emailjs from "@emailjs/browser";
import { useToast } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
const Regesteration = (props) => {
  useEffect(() => {
    document.title = "ZEON | Join";
  });

  const form = useRef();
  const toast = useToast();
  // ---- For email validation ----
  const [email, setEmail] = useState("");
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  // ---- for submission limits ----
  const [submissionCount, setSubmissionCount] = useState(0);
  const lastSubmissionTimestamp = useRef(0);
  useEffect(() => {
    const storedTimestamp = localStorage.getItem("lastSubmissionTimestamp");
    if (storedTimestamp) {
      lastSubmissionTimestamp.current = storedTimestamp;
    }
  }, []);
  useEffect(() => {
    const now = new Date().getTime();
    if (
      now - lastSubmissionTimestamp.current < 300000 &&
      submissionCount >= 3
    ) {
      // wait for 5 minutes
      return;
    }
  });

  const submitHandler = (e) => {
    e.preventDefault();
    const input_one = document.querySelector(".input-field-one").value.length;
    const input_two = document.querySelector(".input-field-two").value.length;
    const input_three =
      document.querySelector(".input-field-three").value.length;

    if (submissionCount >= 3) {
      toast({
        title: "هواي معلومات",
        description: "انتظر شوي حبيبي",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return;
    } else {
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(email)) {
        toast({
          title: "ايميلك غلط حبي",
          description: "عيد كتابة الايميل بالشكل الصحيح",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        if (input_one >= 2 && input_two >= 5 && input_three >= 5) {
          emailjs
            .sendForm(
              "service_ef5hi7d",
              "template_6qbvb3u",
              form.current,
              "orb_EdVwD868rW8eB"
            )
            .then(
              function (response) {
                console.log("SUCCESS!", response.status, response.text);
                toast({
                  title: "Sent!",
                  description: "I will call you soon.",
                  status: "success",
                  duration: 4000,
                  isClosable: true,
                });
                setSubmissionCount(submissionCount + 1);
                lastSubmissionTimestamp.current = new Date().getTime();
                localStorage.setItem(
                  "lastSubmissionTimestamp",
                  lastSubmissionTimestamp.current
                );
              },
              function (error) {
                console.log("FAILED...", error);
                toast({
                  title: error,
                  description: "Follow up the error please.",
                  status: "warning",
                  duration: 4000,
                  isClosable: true,
                });
              }
            );
        } else {
          toast({
            title: "Fill the Fields",
            description: "Fill all the field please.",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    }

    e.target.reset();
  };
  return (
    <ChakraProvider>
      <section className="section reg-section">
        <Slider
          mainImg={Man}
          setActiveBar={props.setActiveBar}
          activeBar={props.activeBar}
        />

        <div className="reg-wrapper container">
          <div className="title">
            <h1>Join</h1>
            <p>Enter Your info and wait for my call</p>
          </div>
          <form ref={form} onSubmit={submitHandler}>
            <label htmlFor="name">Your name</label>
            <input
              className="input-field-one"
              type="text"
              name="name"
              placeholder="Enter your name ..."
            />
            <label htmlFor="name">Your email</label>
            <input
              onChange={handleChange}
              className="input-field-two"
              type="text"
              name="email"
              placeholder="Enter your email ..."
            />
            <label htmlFor="name">Your number</label>
            <input
              className="input-field-three"
              type="text"
              name="number"
              placeholder="+964 "
            />
            <input className="btnSending" type="submit" value={"Send"} />
          </form>
        </div>
      </section>
    </ChakraProvider>
  );
};

export default Regesteration;
