import React from "react";
// import { Parallax } from "react-scroll-parallax";
import { ReactComponent as SVG3 } from "../../../Images/plants/2cos.svg";
// import SVG4 from "../../../Images/4.png";
const FloatingImagesTwo = () => {
  return (
    <>
      <div className="plants-img-section three">
        <div className="plants-img-container">
          <SVG3 className="mySVG3" />
        </div>
      </div>
      {/* <div className="plants-img-section five">
        <Parallax translateY={[50, -50]} translateX={[40, -40]}>
          <div className="plants-img-container">
            <img src={SVG4} alt="" />
          </div>
        </Parallax>
      </div> */}
    </>
  );
};

export default FloatingImagesTwo;
