import React from "react";
import SliderLinks from "./Box/Links/SliderLinks";
import LogoSection from "./Box/LogoSection/LogoSection";
// import Wave from "../WaveBG/Wave";
import "./style/style.css";

const Slider = (props) => {
  return (
    <div className={props.activeBar ? `slider slider-active` : `slider`}>
      <div className="container">
        <LogoSection setActiveBar={props.setActiveBar} />
        <SliderLinks />
        {/* <Wave WaveVideo={require("../../Images/Ai Motion32.mp4")} /> */}
      </div>
    </div>
  );
};

export default Slider;
