import React, { useEffect } from "react";
import "./style/style.css";
import Footer from "../../component/Footer/Footer";
import Slider from "../../component/Slider/Slider";
import Video from "../../Images/Ai Motion29.mp4";
import FlaotingImagesOne from "./Box/FlaotingImagesOne";
import TheContainer from "./Box/TheContainer";
import FloatingImagesTwo from "./Box/FloatingImagesTwo";

const Courses = ({ setActiveBar, activeBar }) => {
  useEffect(() => {
    document.title = "ZEON | Courses";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="section courses-section">
        <Slider setActiveBar={setActiveBar} activeBar={activeBar} />
        <div className="courses img-one">
          <div className="mySpaceImage"></div>
        </div>
        <div className="courses-wrapper">
          <FlaotingImagesOne />
          <TheContainer />
          <FloatingImagesTwo />
        </div>
        <Footer WaveVideo={Video} />
      </section>
    </>
  );
};

export default Courses;
