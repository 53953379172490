import React from "react";
import Links from "../../../NavBar/Boxes/Links/Links";
import "./style/style.css";

// This component is to display the links in the nav bar.
// It imports the Links component from the NavBar component.
// It styles these links differently.
const SliderLinks = () => {
  return (
    <div className="links-section">
      <Links signin={true} signup={true}/>
    </div>
  );
};

export default SliderLinks;
