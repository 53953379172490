import React from "react";
import "./style/Content.css";
import AnimatedBG from "../../../component/AnimatedBG/AnimatedBG";
import Findme from "../../../component/Findme/Findme";

const Content = () => {
  return (
    <div className="applications-section">
      {/* Seprator Image */}
      <div className="mySpaceImage"></div>
      {/* Planet background image */}
      <div className="img-bg-planet"></div>
      {/* Animated Bubble Background */}
      <AnimatedBG />
      {/* Content */}

      <div className="container">
        <div className="intro-content">
          <h1>القنوات</h1>
          <p>انضم الي القنوات</p>
        </div>
        <Findme />
      </div>
    </div>
  );
};

export default Content;
