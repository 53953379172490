import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Data from "../../../../Data/Data";
import "./style/style.css";

const Box = (props) => {
  const { socialMedia } = Data;

  return (
    <div className="left-side-desc">
      <h1>Join our community</h1>
      <ul>
        {socialMedia.map((item, index) => {
          return (
            <li key={index}>
              <a href={`${item.link}`}>
                <FontAwesomeIcon
                  icon={
                    require("@fortawesome/free-brands-svg-icons")[
                      Data.socialMedia[index].icon
                    ]
                  }
                />
                <span>{item.iconName}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Box;
