import React from "react";
import "./style/AnimatedBG.css";
// =============================================================
// This component generate a floating buuble on the page
// =============================================================
const BubbleBG = () => {
  return (
    <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  );
};

export default BubbleBG;
