import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import NavBar from "./component/NavBar/NavBar";
import AppRoutes from "./routes/AppRoutes";
import useToggle from "./hooks/useToggle";
import "./App.css";

const App = () => {
  const [activeBar, toggleActiveBar] = useToggle(false);

  return (
    <main className="App">
      <BrowserRouter>
        <NavBar setActiveBar={toggleActiveBar} />
        <ParallaxProvider>
          <AppRoutes activeBar={activeBar} toggleActiveBar={toggleActiveBar} />
        </ParallaxProvider>
      </BrowserRouter>
    </main>
  );
};

export default App;
