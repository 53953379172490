import React from "react";
import "./style/Findme.scss";

const Findme = () => {
  return (
    <>
      {/* ======== Start Box ======== */}
      <div className="app-list">
        {/* <div className="apps-box"> */}
        {/* ============================================ */}
        {/* <div className="content-side">
            <h3 className="logo-name">التطبيقات</h3>
            <p className="logo-desc">
              <p>جميع انواع التطبيقات المدفوعه تحصلها ببلاش </p>
              <p>تحديثات مستمره</p>
              <p>دعم فني 24/7</p>
            </p>
            <a href="https://t.me/HAMADA_1997100" className="get-app">
              انضم
            </a>
          </div> */}
        {/* </div> */}
        <div className="apps-box">
          {/* ============================================ */}
          <div className="content-side">
            <h3 className="logo-name">صفحة الانستغرام</h3>
            <p className="logo-desc">
              <p>آخر اخبار الكورس</p>
              <p>تحديثات مستمره</p>
              <p>اسئله دورية</p>
              <p>اعلانات عن الفرص المتعدده</p>
            </p>
            <a href="https://instagram.com/alakbar_iq" className="get-app">
              انضم
            </a>
          </div>
        </div>
      </div>
      {/* ======== End Box ======== */}
    </>
  );
};

export default Findme;
