import React, { useState } from "react";
import CoursesBox from "../../../component/CoursesBox/CoursesBox";
import MenuList from "../../../component/MenuList/MenuList";
// Importing JSON file
import Data from "../../../Data/Data.json";
// Importing AOS library
// import Aos from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
// ========================================================
//   This Component contains :
//   1. The CoursesBox Component (Which includes the Boxes of Courses).
//   2. The Pagination Component (That Takes the Below Variables).
//   3. The component sorts the boxes based on their course name.
// ========================================================

const TheContainer = () => {
  // // For AOS Srolling Effect
  // useEffect(() => {
  //   Aos.init({
  //     delay: 200,
  //   });
  // }, []);
  // ===========================================================
  // ====== Start Sorting When choosing a specific course ======
  // [1] Create a setState to go to the MenuList .. When Click on a specific course, State will be Updated
  // [2] Make a conditional statement based on the State .. if the State is Equal to Some Course,
  //     - then Print only the related courses using filter() and map() functions.
  //     - these courses data will be imported from the JSON file (BoxCourse).
  // [3] The component we are going to filter and map through is the (CoursesBox) Component
  // [4] The (courseBox) component is inside a function called (mapping())
  //     - The printing will be: importing courseBox component --> mapping() --> mappingCard()
  // ===========================================================
  // The setState that will go to the MenuList Component
  const [courseName, setCourseName] = useState("first-stage");

  const { boxCourse } = Data;

  // 1- Here is the main sorting function, which takes the (mapping()) function:
  const mappingCard = () => {
    switch (courseName.toLowerCase()) {
      case "first-stage":
        // On defalut it will be the first stage courses .. it will display the mapped data and the button
        return (
          <>
            <div
              // data-aos="fade-down"
              // data-aos-duration="1000"
              // data-aos-offset="100"
              className="coursesBox"
            >
              {boxCourse
                .filter((itemName) => itemName.stage.toLowerCase() === "first")
                .map(mapping())}
            </div>
            <div className="getting-btn-container">
              <NavLink className={"join-link"} to="/courses/choose/join">
                <span>احجز مقعدك</span>
              </NavLink>
            </div>
          </>
        );
      // --------------------------------------------
      // Anything will come soon should not have any button
      default:
        return (
          <div style={{ fontSize: "23px", color: "white"}}>
            جاي بالطريق 
            <FontAwesomeIcon style={{margin: "0 20px", display:"inline-block"}} color="#FFC222" size="lg" icon={faSmile} />
          </div>
        );
      // --------------------------------------------
    }
  };

  // 2- (Mapping()) function that take the courseBox component.
  const mapping = () => {
    return (item, id) => (
      <CoursesBox
        id={id}
        courseName={item.courseName}
        briefDescription={item.briefDescription}
        img={item.img}
        price={item.price}
        isGold={item.isGolden}
      />
    );
  };

  // ====== End Sorting When choosing a specific course ======
  // ===========================================================



  return (
    <div className="container">
        <div
          // data-aos="fade-down"
          // data-aos-duration="1000"
          // data-aos-offset="100"
          className="title"
        >
          <h1>Courses</h1>
          <p>Programming Languages</p>
        </div>
        {/* This conpoent contains the tabs of courses names (either stage 1 or stage 2) */}
        <MenuList courseName={courseName} setCourseName={setCourseName} />
      {/* Here is the courses printed */}
      {mappingCard()}
    </div>
  );
};

export default TheContainer;
