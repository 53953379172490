import React from "react";
import "./style/style.css";
import Video from "../Video/Video.jsx";

const Wave = (props) => {
  return (
    <div className="wave-bg-container">
      <Video src={props.WaveVideo} />
    </div>
  );
};

export default Wave;
