import React, { useEffect } from "react";
import "./style/style.css";
import Aos from "aos";
import Video from "../Video/Video";

const MotionVideo = (props) => {
  useEffect(() => {
    Aos.init({
      delay: 200,
    });
  }, []);

  return (
    <section className="motion-video">
      <div className={props.ltr ? `container ltr` : `container rtl`}>
        <div
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-offset="100"
          className="video-side"
        >
          <Video src={props.VideoPath} />
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1500"
          data-aos-offset="100"
          className="desc-side"
        >
          <h1>{props.h1}</h1>
          <p className="subTitle" style={{ width: "100%" }}>
            {props.p}
          </p>
          <ul>
            {props.ul.map((item, id) => {
              return <li key={id}>{item}</li>;
            })}
          </ul>
          <div className="btn">
            <a href="https://www.instagram.com/alakbar_iq/">الكورسات</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MotionVideo;
