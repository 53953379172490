import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import Data from "../../../Data/Data.json";
import { NavLink } from "react-router-dom";
import Image from "../../Image/Image";

const SwiperComponent = () => {
  const { languages } = Data;
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={1}
      loop={true}
      freeMode={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      centeredSlides={true}
      navigation={{
        clickable: true,
      }}
      modules={[Navigation, Pagination, Autoplay]}
      className="mySwiper"
    >
      {languages.map((item, index) => {
        return (
          <SwiperSlide>
            <NavLink to="/courses">
              <div key={index} className="img-container">
                <Image src={require(`../../../Images/${item.img}`)} alt="" />
              </div>
            </NavLink>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default SwiperComponent;
