import React, { useEffect, useState } from "react";
import HiddenBars from "./Boxes/HiddenBars/HiddenBars";
import Links from "./Boxes/Links/Links";
import Logo from "./Boxes/Logo/Logo";
import Profile from "./Boxes/Profile/Profile";
import "./style/style.css";
import { useLocation } from "react-router-dom";

const NavBar = (props) => {
  // This state is to change color of nav bar on scrolling.
  const [navBar, setNavBar] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 80) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };
  window.addEventListener("scroll", changeColor);

  const location = useLocation();
  const [navBg, setNavBg] = useState("");
  const [isNavRelative, setIsNavRelative] = useState(false);
  useEffect(() => {
    switch (window.location.pathname) {
      case "/courses":
        setNavBg("courses-nav-bg");
        break;
      case "/courses/choose/join":
        setNavBg("courses-nav-bg");
        break;
      case "/courses/choose":
        setNavBg("courses-nav-bg");
        break;
      case "/about":
        setNavBg("about-nav-bg");
        break;
      case "/register":
        setNavBg("courses-nav-bg");
        setIsNavRelative(true);
        break;
      default:
        setNavBg("");
        break;
    }
  }, [location]);
  return (
    <section
      style={{ position: isNavRelative ? "relative" : "fixed" }}
      className={navBar ? `header active-nav-bar ${navBg}` : `header ${navBg}`}
      id="header"
    >
      <div className="container">
        <Logo />
        <Links signin={false} signup={false} />
        <Profile />
        <HiddenBars setActiveBar={props.setActiveBar} />
      </div>
    </section>
  );
};

export default NavBar;
