import React from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style/style.css";
import Image from "../../../Image/Image";

const LogoSection = (props) => {
  return (
    <div className="logo-section">
      <div className="logo-container">
        <Image src={require("../../../../Images/zeon 3.png")} alt="logo"/>
      </div>
      <div onClick={props.setActiveBar} className="close-icon-side">
        <FontAwesomeIcon icon={faClose} />
      </div>
    </div>
  );
};

export default LogoSection;
