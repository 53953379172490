import React from "react";
import "./style/LandingPage.css";
import "aos/dist/aos.css";
import LandingLeft from "./Box/Left/LandingLeft";
import LandingRight from "./Box/Right/LandingRight";
// import Wave from "../WaveBG/Wave";
const LandingPage = (props) => {
  return (
    <div className={"landing-page-section"}>
      <div className="landing-page-container container">
        {/* ============================= */}
        {/* I can switch between an image and video background */}
        {/* ============================= */}
        {/* <div className="bg-main-two">
          <img src={require("../../Images/bg.jpeg")} alt="" />
        </div> */}
        {/* <Wave WaveVideo={require("../../Images/Ai Motion29.mp4")} /> */}
        {/* ================================== */}
        {/* ======== Start Right Side ======== */}
        <LandingLeft mainImg={props.mainImg} />
        {/* ======== End Right Side ======== */}
        {/* ================================== */}
        {/* ======== Start Left Side ======== */}
        <LandingRight />
        {/* ======== End Left Side ======== */}
        {/* ======== Start Planet Side ======== */}
        {/* ======== End Planet Side ======== */}
      </div>
    </div>
  );
};

export default LandingPage;
