import React from "react";

const Video = ({
  src,
  style = {},
  className = "videoClass",
  id = "JSONVideo",
}) => {
  return (
    <video
      style={style}
      className={className}
      autoPlay
      muted
      loop="True"
      id={id}
      src={src}
    />
  );
};

export default Video;
