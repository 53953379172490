import React from "react";
import { Parallax } from "react-scroll-parallax";

import "./style/LandingSection.css";
const LandingSection = () => {
  return (
    <>
      <div className="text-part">
        <div className="content container">
          <h1>من انا</h1>
          <p>
            <p>
              انا مهندس برمجيات بخبرة طويلة وعميقة في عالم التكنولوجيا
              والبرمجة.
            </p>
            <p>
              إلى جانب خبرتي التقنية، أملك اهتماماً قوياً بعلم النفس وعلم
              الأعصاب، حيث أعتقد بشدة في القوة التي يمكن أن تنجم عن دمج هذه
              العلوم مع علوم الكمبيوتر.
            </p>
            <p>
              كما أني أعمل كصانع محتوى، حيث أشارك معرفتي وشغفي بالتكنولوجيا
              وعلوم الإنسان مع جمهور أوسع.
            </p>
          </p>
          <button data-text="احجز مقعدك">احجز مقعدك</button>
        </div>
        {/* ============ This conpoent contains the courses names ============ */}
      </div>
      {/* ======================================== */}
      <div className="img-bg-part">
        <div className="img img-main"></div>
        <Parallax className="img img-moon" translateY={[-30, 30]}></Parallax>
        <div className="img img-trees"></div>
        <Parallax className="img img-animals" translateY={[10, -10]}></Parallax>
      </div>
    </>
  );
};

export default LandingSection;
