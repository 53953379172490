import React from "react";
import "./style/style.css";
import DescriptionSide from "./Box/Description/DescriptionSide";
import Wave from "../WaveBG/Wave";
// import "aos/dist/aos.css";
// import Aos from "aos";
const Footer = (props) => {
  // For AOS Srolling Effect
  // useEffect(() => {
  //   Aos.init({
  //     delay: 200,
  //   });
  // }, []);
  return (
    <section className="footer" style={props.styleBackground}>
      <div
        // data-aos="fade-right"
        // data-aos-duration="1400"
        // data-aos-offset="100"
        className="container"
      >
        <DescriptionSide />
      </div>
      <span>
        <Wave WaveVideo={props.WaveVideo} />
      </span>
    </section>
  );
};

export default Footer;
