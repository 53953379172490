import React, { useEffect } from "react";
import "./style/style.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Importing AOS library
import "aos/dist/aos.css";
import Aos from "aos";
import SwiperComponent from "./Box/SwiperComponent";
import Wave from "../WaveBG/Wave";

// ======================================================
// This component displays the slider of courses
// This slider is from Swiper library.
// The content are retrieved from Data.json file and mapped through.
// ======================================================
const CourseBar = () => {
  // For AOS Srolling Effect
  useEffect(() => {
    Aos.init({
      delay: 200,
    });
  }, []);

  return (
    <section
      data-aos="fade-down"
      data-aos-duration="1500"
      data-aos-offset="100"
      className="course-bar"
    >
      <h1>الكورسات</h1>
      <div className="container">
        <SwiperComponent />
        <div data-aos="fade" data-aos-duration="1500" data-aos-offset="100">
          <Wave WaveVideo={require("../../Images/Ai Motion28.mp4")} />
        </div>
      </div>
    </section>
  );
};

export default CourseBar;
