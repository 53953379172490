import { Parallax } from "react-scroll-parallax";
import React, { useEffect } from "react";
import "./style/LandingRight.css";
import "aos/dist/aos.css";
import Aos from "aos";
import { NavLink } from "react-router-dom";
const LandingRight = () => {
  // For AOS Srolling Effect
  useEffect(() => {
    Aos.init({
      delay: 200,
    });
  }, []);

  return (
    <div className="right-side">
      <Parallax translateY={[-40, 0]}>
        <h1
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-offset="100"
        >
        هذه المنصه
          <span className="neon-wrapper">
            <span className="neon-text flicker-slow"> ز</span>
            <span className="neon-text flicker-fast">يو</span>
            <span className="neon-text flicker-mid">ن</span>
          </span>
          <br />
          اهلا بكم
          <br />
        </h1>
        <p
          className="paragraph"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-offset="100"
        >
          انا المؤسس، علي اكبر
        </p>

        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-offset="20"
          className="checking-btn"
        >
          <NavLink to={"/courses"}>خذلك كورس</NavLink>
        </div>
      </Parallax>
    </div>
  );
};

export default LandingRight;
