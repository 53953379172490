import React from "react";
import "./style/CoursesBox.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Image from "../Image/Image";
const CoursesImages = (props, { id }) => {
  return (
    <div key={id} className="box">
      <div
        style={{ display: props.isGold ? "flex" : "none" }}
        className="golden"
      >
        <p>الأكثر شراءاً</p>
      </div>
      <div className="img-container">
        <Image src={require(`../../Images/gifs/${props.img}`)} alt="" />
        <span className={"join-item"} to="/courses/join">
          {props.price}
        </span>
      </div>
      <div className="text-container">
        <h1>{props.courseName}</h1>
        <div className="features">
          {props.briefDescription.map((item, id) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon color="green" icon={faCheck} />
                <p style={{ margin: "0" }} key={id}>
                  {item}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CoursesImages;
