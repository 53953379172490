import React, { useEffect } from "react";
import Content from "./Content/Content";
import LandingSection from "./LandingSection/LandingSection";
import "./style/About.scss";
import Footer from "../../component/Footer/Footer";
import Slider from "../../component/Slider/Slider";

const About = ({ activeBar, setActiveBar }) => {
  useEffect(() => {
    document.title = "ZEON | Find me";
    window.scrollTo(0, 0);
  });

  return (
    <section className="section about-section">
      <Slider setActiveBar={setActiveBar} activeBar={activeBar} />
      <LandingSection />
      <Content />
      <Footer WaveVideo={require("../../Images/Ai Motion32.mp4")} />
    </section>
  );
};

export default About;
