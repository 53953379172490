import React, { useEffect } from "react";
import LandingPage from "../../component/LandingPage/LandingPage";
import Mars from "../../component/MarsSection/Mars";
import MotionVideo from "../../component/MotionVideoSection/MotionVideo";
import Slider from "../../component/Slider/Slider";
import Man from "../../Images/astr.png";
import "./style/style.css";
import Data from "../../Data/Data.json";
import CourseBar from "../../component/courseBar/CourseBar";
import Footer from "../../component/Footer/Footer";

const Home = ({ activeBar, setActiveBar }) => {
  useEffect(() => {
    document.title = "ZEON | Home";
    window.scrollTo(0, 0);
  });

  const renderVideoSections = () =>
    Data.videoSection.map((item, index) => (
      <MotionVideo
        key={index}
        {...item}
        VideoPath={require(`../../Images/${item.VideoPath}.mp4`)}
      />
    ));

  return (
    <section className="section home-section">
      <Slider setActiveBar={setActiveBar} activeBar={activeBar} />
      <LandingPage mainImg={Man} />
      <Mars />
      {renderVideoSections()}
      <CourseBar />
      <Footer WaveVideo={require("../../Images/Ai Motion29.mp4")} />
    </section>
  );
};

export default Home;
