import React, { useEffect, useState } from "react";
import "./style/Register.css";
import Man from "../../Images/astr.png";
import ghost from "../../Images/akbar.jpg";
import Slider from "../../component/Slider/Slider";
import Image from "../../component/Image/Image";

const Register = (props) => {
  const [checkMark, setCheckMark] = useState(false);
  useEffect(() => {
    document.title = "ZEON | Sign in";
  });
  return (
    <section className="section register-section">
      <Slider
        mainImg={Man}
        setActiveBar={props.setActiveBar}
        activeBar={props.activeBar}
      />
      <form action="/bla/bla/bla">
        <p>Only Ali Akbar can log in haha!!!</p>
        <div className="img-container">
          <Image src={ghost} alt="" />
        </div>
        <div className="input-wrapper">
          <input type="email" placeholder="Enter your email..." />
        </div>
        <div className="input-wrapper">
          <input type="password" placeholder="password" />
        </div>
        <div className="remember-me-wapper">
          <label
            className={checkMark ? "apply-checkmark" : ""}
            onClick={() => setCheckMark(!checkMark)}
            htmlFor="rememberme"
          >
            Remember me
          </label>
          <input id="rememberme" type="checkbox" />
        </div>
        <div className="input-wrapper">
          <button>Login</button>
        </div>
      </form>
    </section>
  );
};

export default Register;
