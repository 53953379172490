import "./style/style.css";
import React, { useEffect } from "react";
import "aos/dist/aos.css";
import Aos from "aos";
import Image from "../../../Image/Image";

const LandingLeft = (props) => {
  // For AOS Scrolling Effect
  useEffect(() => {
    Aos.init({
      delay: 200,
    });
  }, []);

  return (
    <div className="left-side">
      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-offset="100"
        className="img-container"
      >
        <div className="bg-main">
          <Image
            src={require("../../../../Images/bg.webp")}
            alt="Background"
          />
        </div>
        <Image
          src={props.mainImg}
          alt="Main"
          className="bg-man"
        />
      </div>
    </div>
  );
};

export default LandingLeft;
