import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Image from "../../../Image/Image";

const Profile = () => {
  const [switcher, setSwitcher] = useState(true);

  return (
    <div
      onClick={() => {
        setSwitcher(!switcher);
      }}
      className="leftSide"
    >
      <NavLink className={"profile-signIn"} to="/register">
        <div className="img_profile">
          <div className="imgContainer">
            <Image src={require("../../../../Images/guest_image.jpg")} alt="" />
          </div>
          <span className="signin">تسجيل</span>
        </div>
      </NavLink>
    </div>
  );
};

export default Profile;
