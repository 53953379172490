import React from "react";
import "./style/style.css";

// This component is for the hidden bars in the nav bar.
// This bar icon will be appeared on small screen.
const HiddenBars = (props) => {
  return (
    <div onClick={props.setActiveBar} className="hidden-bars">
      {/* <FontAwesomeIcon icon={faBars}/> */}
      <span className="bar-container">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </div>
  );
};

export default HiddenBars;
