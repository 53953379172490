import React from "react";
import { NavLink } from "react-router-dom";
import "./style/style.css";

// This component is to display the Links.
const Links = (props) => {

  return (
    <ul className="quickAccess">
      <li>
        <NavLink to="/Home">الصفحة الرئيسية</NavLink>
      </li>
      <li>
        <NavLink to="/courses">الكورسات</NavLink>
      </li>
      <li>
        <NavLink to="/about">حول</NavLink>
      </li>
      {/* <li style={{ display: props.signin ? "block" : "none" }}>
        <NavLink to="/register">تسجيل الدخول</NavLink>
      </li> */}
    </ul>
  );
};

export default Links;
